<template>
  <Layout>
    <div class="visit-room">
      <span v-if="error" class="visit-room-err">
        {{ error }}
      </span>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <div>
        <div v-if="!roomIsFinished" class="visit-room-header">
          <!--          <v-chip v-if="roomIsStarted && !roomIsFinished && timer" label color="red darken-1" dark>-->
          <!--            {{ timer }}-->
          <!--          </v-chip>-->
          <span v-if="remoteParticipant" class="visit-room-header-select">
            {{ $t("visits.visitRoomWith") }} {{ remoteParticipant && remoteParticipant.fullName }}
          </span>
          <div v-if="dateFormated" class="visit-room-header-bordered">
            <v-icon color="icon">mdi-calendar-blank</v-icon>
            <p>{{ dateFormated }}</p>
          </div>
          <div v-if="timeRange" class="visit-room-header-bordered">
            <v-icon color="icon">mdi-clock-outline</v-icon>
            <p>{{ timeRange }}</p>
          </div>
        </div>
        <v-row class="visit-room-content" no-gutters>
          <MeetingVideoRoom v-if="!roomIsFinished && !roomNotAvailable" />
          <div v-if="roomIsFinished || roomNotAvailable" class="visit-room-err">
            <template v-if="!isLoggedIn">
              <img class="visit-room-err_img" src="@/assets/chat/registration_congratulations.svg" />
              <span class="visit-room-err_text"> Log Into your Account. </span>
              <span class="visit-room-err_text_small"> You need to Log In to access this page. </span>
              <v-btn class="visit-room-err_btn" color="primary" outlined @click="gotoLogin"> Log In</v-btn>
            </template>
            <template v-else>
              <img class="visit-room-err_img" src="@/assets/chat/registration_congratulations.svg" />
              <span class="visit-room-err_text"> Meeting room has finished </span>

              <v-btn class="visit-room-err_btn" color="primary" outlined @click="gotoProfile"> Go to profile</v-btn>
            </template>
          </div>
        </v-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Layout from "@/components/Layout";
import MeetingVideoRoom from "@/components/MeetingRoom/MeetingVideoRoom";
import { meetingRoomMixin } from "@/mixins/meetingRoom";
import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  components: {
    MeetingVideoRoom,
    Layout,
  },
  mixins: [meetingRoomMixin],
  computed: {
    ...mapState(useAuthStore, ["isLoggedIn", "userIsPatient", "userIsPractitioner"]),
    ...mapState(useMeetingRoomStore, ["remoteParticipant", "roomIsFinished", "roomNotAvailable"]),
  },
  watch: {
    async $route() {
      await this.resetRoom();
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["resetRoom"]),
    gotoLogin() {
      this.$router.push("/auth/signin");
    },
    gotoProfile() {
      if (this.userIsPatient) this.$router.push("/patient");
      if (this.userIsPractitioner) this.$router.push("/patient");
    },
  },
  async destroyed() {
    this.resetRoom();
  },
};
</script>

<style lang="scss" scoped>
.visit-room-err {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_img {
    margin-bottom: 2em;
  }

  &_text {
    margin-bottom: 0.3em;
    font-size: 28px;
    font-weight: 500;
    color: #33343e;
  }

  &_text_small {
    margin-bottom: 2em;
    font-size: 14px;
    font-weight: 400;
    color: #91909c;
  }

  &_btn {
    letter-spacing: normal;
    text-transform: initial;
  }
}
</style>
